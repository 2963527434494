export default [
  {
    url: "https://res.cloudinary.com/dnljd8mlo/video/upload/v1589752971/float7_ms7epj.wav",
    title: 'The Drip',
    artist: ['Hexer Quiz']
  },
  {
    url: 'https://res.cloudinary.com/dnljd8mlo/video/upload/v1589755451/dp_xtwrma.wav',
    title: 'Human After All - Daft Punk (Hexer Quiz Remix)',
    artist: ['Hexer Quiz']
  }
];